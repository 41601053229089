import { h, Component } from 'preact';
import { Router } from 'preact-router';
import { route } from 'preact-router';
import { Link } from 'preact-router/match';

import Match from 'preact-router/match';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import Czym_sie_zajmujemy from '../routes/czym-sie-zajmujemy';
import Dla_klienta from '../routes/dla-klienta';
import Akty_prawne from '../routes/akty-prawne';
import Kontakt from '../routes/kontakt';

import AOS from 'aos';

import Footer from "./Footer";

import './../vendor/aos/aos.css';

import LazyLoad from 'vanilla-lazyload';


let navli = [
	{ id: "/", name: "Strona główna" },
	{ id: "/czym-sie-zajmujemy", name: "Czym się zajmujemy?" },
	{ id: "/dla-klienta", name: "Dla klienta" },
	{ id: "/akty-prawne", name: "Akty prawne" },
	{ id: "/kontakt", name: "Kontakt" }
]

class App extends Component {

	constructor(props)
	{
		super(props);
	}

	componentDidUpdate(){
		if(typeof document.lazyLoadInstance !== "undefined")
		{
			document.getElementById("footer").classList.remove("footer-animate");
			setTimeout(() => {
				document.lazyLoadInstance.update();
				document.getElementById("footer").classList.add("footer-animate");
			}, 400);
		}
	}

	async componentDidMount() {

		//window.LazyLoad = await import ('./../assets/LazyLoad.js');
		await import('./../../node_modules/bootstrap/dist/js/bootstrap.bundle');

		//const lazyLoadOptions = {/* your options here */};
		
		AOS.init({
			duration: 600,
			easing: "ease-in-out",
			once: true,
			mirror: false,
		});

		if (!document.lazyLoadInstance) {
			document.lazyLoadInstance = new LazyLoad({ elements_selector: "img" });
		}
		document.lazyLoadInstance.update();

		document.getElementById("footer").classList.add("footer-animate");

		document.querySelector(".mobile-nav-toggle.d-lg-none").addEventListener("click", function() {
			document.querySelector("body").classList.add("mobile-nav-active")
		});
		document.querySelector(".mobile-nav-overly").addEventListener("click", function() {
			document.querySelector("body").classList.remove("mobile-nav-active")
		});
	}

	handleRoute = e => {
		if (typeof window !== "undefined") {
			document.querySelector("body").classList.remove("mobile-nav-active");
			window.scrollTo(0, 0);
		}

		this.setState({ route: !this.state.route });
	};


	render() {


		return (
			<div id="app">

				<Header />
				<Router onChange={this.handleRoute}>
					<Home path="/" />
					<Czym_sie_zajmujemy path="/czym-sie-zajmujemy/"/>
					<Dla_klienta path="/dla-klienta/"/>
					<Akty_prawne path="/akty-prawne/"/>
					<Kontakt path="/kontakt/"/>
					<Profile path="/profile/" user="me" />
					<Profile path="/profile/:user" />
				</Router>

				<nav class="mobile-nav d-lg-none">
					<ul>
						{
							navli.map(function (item, i) {
								return <li><Link activeClassName="active" href={item.id}>{item.name}</Link></li>;
							})
						}

						{/* <li class="get-started" style="margin-top: 15px;">
							<a data-toggle="modal" data-target="#Modal_wycena"
							onClick={ ( () => {

								document.querySelector("body").classList.remove("mobile-nav-active")
			
							}) }
							>Wycena</a>
							<a data-toggle="modal" data-target="#Modal_harmonogram"
							onClick={ ( () => {

								document.querySelector("body").classList.remove("mobile-nav-active")
			
							}) }>Odbiór</a>
						</li> */}
					</ul>
				</nav>

				<Footer/>

				<div class="mobile-nav-overly"></div>
			</div>
		)

	}

}

export default App;
