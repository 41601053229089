import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import ThreeLines from './../SVG/ThreeLines';
import Logo from './../SVG/Logo';

import Modal_wycena from './../Modal_wycena';
import Modal_harmonogram from './../Modal_harmonogram';

let navli = [
	{ id: "/", name: "Strona główna" },
	{ id: "/czym-sie-zajmujemy", name: "Czym się zajmujemy?" },
	{ id: "/dla-klienta", name: "Dla klienta" },
	{ id: "/akty-prawne", name: "Akty prawne" },
	{ id: "/kontakt", name: "Kontakt" }
]


class Header extends Component {

	render() {

		return (

			<div>

				<button type="button" class="mobile-nav-toggle d-lg-none"
				><i class="icofont-navigation-menu"><ThreeLines /></i>
				</button>


				<header id="header" class="fixed-top d-flex align-items-center" style="transition: none 0s ease 0s;">
					<div class="container d-flex align-items-center" style="position: relative">
						<div style="display:none" class="alert alert-success">
							Wiadomość została wysłana
		</div>

						<div class="logo mr-auto">
							<h1 class="text-light">
								<Logo />
							</h1>



						</div>

						<nav class="nav-menu d-none d-lg-block">
							<ul>
								{
									navli.map(function (item, i) {
										return <li><Link activeClassName="active" href={item.id}>{item.name}</Link></li>;
									})
								}
								{/* <li class="get-started">
									<a data-toggle="modal" data-target="#Modal_wycena">Wycena</a>
									<a data-toggle="modal" data-target="#Modal_harmonogram">Odbiór</a>
								</li> */}
							</ul>
						</nav>
					</div>
				</header>

				{/* <Modal_wycena />
				<Modal_harmonogram /> */}

			</div>

		)

	}

}

export default Header;
