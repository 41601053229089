import { h, Component } from 'preact';

import Logo from './../SVG/Logo';

import "./style.css";

class Footer extends Component {

    render() {

        return (

            <footer id="footer" class="footer-init">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-6 text-right text-lg-left order-2 order-lg-1">
                            <div class="contact-about">
                                <div style="margin-bottom: 6px">
                                    <Logo></Logo>
                                </div>
                                <p>
                                    Specjalizujemy się w <strong>utylizacji odpadów medycznych</strong>, <strong>utylizacji odpadów</strong> i <strong>transporcie odpadów</strong> na terenie województwa śląskiego oraz małopolskiego.
                                    Nasza firma bezpiecznie transportuje, <strong>utylizuje odpady niebezpieczne</strong>, w szczególności: <strong>odpady medyczne</strong>, <strong>weterynaryjne</strong>, <strong>odpady chemiczne</strong> oraz <strong>spożywcze</strong>.
                                </p>
                                <div class="text-center text-lg-left">
                                    <div class="copyright">
                                        &copy; <strong>GlobalMed</strong>. Wszelkie prawa zastrzeżone
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6 text-left text-lg-right order-1 order-lg-2" style="margin-bottom: 15px">

                            <h3><strong>Dane adresowe</strong></h3>
                            <p>
                                Gospodarka odpadami
                                <br />
                                Spółka cywilna
                                <br />
                                ul. Limanowskiego 8
                                <br />
                                41-300 Dąbrowa Górnicza
                                <br />
                                NIP: 6292498210
                                <br />
                                REGON: 387447592
                            </p>
                            <p>
                                <strong>
                                    Kontakt telefoniczny w godzinach od 9:00 do 16:00.
                                </strong>
                                {/* <br />
                    Tel:. +48 504-193-719  | +48 513-804-855  | 32 262-31-88 */}
                                <br />
                                E-mail: info@globalmed-go.pl
                            </p>

                        </div>
                    </div>

                </div>
            </footer>

        )

    }

}

export default Footer;